<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <v-data-table
          :key="1"
          :hide-default-header="hideDefaultHeader"
          :headers="headers"
          :items="visibleItems"
          :items-per-page="table.itemsPerPage"
          :footer-props="table.footerProps"
          :options.sync="table.options"
          :server-items-length="table.total"
          :show-expand="adminView"
          :show-select="false"
          :expanded="table.expanded"
          :loading="table.loading"
          single-select
          loading-text="loading"
          must-sort
          sort-by="formId"
          sort-desc
          no-data-text="No forms found"
          no-results-text="No forms found"
          item-key="formId"
          id="table"
          class="table"
          color="brandCyan"
          :item-class="table.rowClass"
          @click:row="rowClick"
          @item-expanded="rowClick"
        >
          <template
            v-slot:[`item.data-table-expand`]="{
              item,
              isExpanded,
              expand
            }"
          >
            <div class="d-flex justify-end">
              <v-btn
                icon
                @click.stop="loadFormSubmissions(item.formId, item.name)"
                class="mr-3"
                ><v-icon color="brandCyan" large
                  >mdi-arrow-right-circle</v-icon
                ></v-btn
              >

              <v-btn
                @click.stop="
                  expand(!isExpanded);
                  rowClick(item);
                "
                v-if="item.clientId != 0"
                icon
                ><v-icon large>{{
                  isExpanded ? "mdi-chevron-up" : "mdi-chevron-down"
                }}</v-icon></v-btn
              >
            </div>
          </template>
          <template v-slot:[`item.displayName`]="{ item }">
            <span class="word-break">{{ item.displayName }}</span>
          </template>
          <template v-slot:[`item.info`]="{ item }">
            <v-btn icon @click.stop="previewItem(item)"
              ><v-icon color="grey">mdi-dots-horizontal</v-icon></v-btn
            >
          </template>
          <!-- <template v-slot:[`item.submissions`]="{ item }">
            <v-btn
              icon
              @click.stop="loadFormSubmissions(item.formId, item.name)"
              class="ml-3"
              ><v-icon color="brandCyan" large
                >mdi-arrow-right-circle</v-icon
              ></v-btn
            >
          </template> -->

          <template v-slot:loading>
            <!-- {{ item }} -->
            <v-progress-circular
              indeterminate
              color="primary"
              class="my-8"
              :size="50"
              :width="4"
            ></v-progress-circular>
          </template>
          <template v-slot:expanded-item="{ item, headers }">
            <!-- <div class="d-flex full-width justify-space-between">
              <span>Left</span><span>Right{{ item }}</span>
            </div> -->
            <td
              id=""
              :colspan="headers.length"
              class="py-4"
              :class="
                $vuetify.breakpoint.sm || $vuetify.breakpoint.xs
                  ? 'px-0'
                  : 'px-6'
              "
              v-if="item"
            >
              <v-row
                ><v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                  :class="{ 'px-7': isMobile }"
                >
                  <div class="d-flex align-center py-2">
                    <v-icon class="mr-2">mdi-list-box-outline</v-icon>
                    <p class="mb-0 ">
                      <v-progress-circular
                        class="mx-auto"
                        indeterminate
                        color="brandCyan"
                        :size="17"
                        :width="2"
                        v-if="loading.analytics.includes(item.formId)"
                      ></v-progress-circular>
                      <span v-else>{{
                        item?.analytics?.totalCompleted || 0
                      }}</span>
                      Completed
                    </p>
                  </div>
                  <div class="d-flex align-center mt-3 py-2">
                    <v-icon class="mr-2">mdi-list-box-outline</v-icon>
                    <p class="mb-0">
                      <v-progress-circular
                        class="mx-auto"
                        indeterminate
                        color="brandCyan"
                        :size="17"
                        :width="2"
                        v-if="loading.analytics.includes(item.formId)"
                      ></v-progress-circular>
                      <span v-else>{{ item?.analytics?.last30Days || 0 }}</span>
                      Completed in last 30 days
                    </p>
                  </div> </v-col
                ><v-col
                  cols="12"
                  sm="12"
                  md="6"
                  lg="6"
                  xl="6"
                  :class="{ 'px-7': isMobile }"
                >
                  <div
                    class="d-flex align-center form-submission-button"
                    @click="loadFormSubmissions(item.formId, item.name)"
                  >
                    <v-icon color="brandCyan" class="mr-2"
                      >mdi-arrow-right-circle</v-icon
                    >
                    <p class="mb-0 ">
                      View submissions
                    </p>
                  </div>
                  <div
                    class="d-flex align-center mt-3 form-submission-button"
                    @click="loadQrCode(item.uuid)"
                  >
                    <v-icon color="brandCyan" class="mr-2">mdi-qrcode</v-icon>
                    <p class="mb-0">
                      Generate QR Link
                    </p>
                  </div>
                </v-col></v-row
              >
            </td>
          </template>
        </v-data-table>
        <v-bottom-sheet v-model="dialog.preview" inset width="500">
          <v-sheet class="text-center" :height="'375px'" v-if="item">
            <div class="pt-4">
              <div class="d-flex align-center justify-space-between mx-6">
                <v-btn fab color="lightGrey" small class="hidden"
                  ><v-icon>mdi-chevron-down</v-icon></v-btn
                >
                <v-card-title class="word-break">
                  <b> {{ item.name }}</b>
                </v-card-title>
                <v-btn
                  fab
                  color="mediumGrey"
                  small
                  depressed
                  elevation="0"
                  class="ignore-click"
                  @click="dialog.preview = !dialog.preview"
                  ><v-icon color="white">mdi-chevron-down</v-icon></v-btn
                >
              </div>
              <div>
                <v-img
                  v-bind:src="item.imageUrl"
                  v-if="item.imageUrl"
                  width="150"
                  max-width="150"
                  :aspect-ratio="16 / 9"
                  class="box-border-global mx-auto"
                />
                <p class="mb-1 mt-4">{{ item.description }}</p>
              </div>
              <v-btn
                v-if="item && item.formId"
                color="brandCyan"
                rounded
                depressed
                width="90"
                @click="selectItem(item, true)"
                class="mt-4 white--text ignore-click"
                >Select</v-btn
              >
            </div>
          </v-sheet>
        </v-bottom-sheet>
      </v-col>
    </v-row>
    <QRViewer
      v-if="qr.dialog"
      :formUUID="qr.formUUID"
      @close="(qr.dialog = false), (qr.formUUID = null), (qr.error = null)"
    />
    <v-dialog
      fullscreen
      v-model="submission.dialog"
      transition="dialog-bottom-transition"
      class="z-index-front"
    >
      <v-card>
        <v-toolbar dark color="brand" rounded="0" elevation="0">
          <v-btn
            dark
            icon
            large
            @click="
              (submission.dialog = false),
                (submission.formId = null),
                (submission.formName = null),
                (submission.error = null)
            "
            class="ml-1"
          >
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
          <v-spacer></v-spacer>
          <v-toolbar-title class="d-flex align-center font-weight-bold"
            ><v-icon class="mr-3"></v-icon
            >{{ submission.formName }} Submissions</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn dark icon large class="ml-1 hidden">
            <v-icon>mdi-close-circle</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row
          class="full-height mt-6 pb-6 container-row"
          justify="center"
          align="center"
          height="100%"
          no-gutters
          ><v-col
            cols="12"
            sm="6"
            md="5"
            class="wizard-main-card rounded-lg px-5 d-flex flex-column justify-space-between"
          >
            <FormSubmissionsTable
              v-if="submission.dialog"
              :formId="submission.formId"
              class="mt-10"/></v-col
        ></v-row>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import FormService from "@/services/FormService";

import QRViewer from "@/components/QR.vue";
import FormSubmissionsTable from "@/components/data-table/FormSubmissions.vue";

import moment from "moment";
import { mapState } from "vuex";

export default {
  name: "FormTable",
  props: {
    search: {
      type: String
    },
    sourcePage: {
      type: String,
      default: "spot-communications"
    },
    sourcePageProps: {
      type: Object,
      default: undefined
    },
    displayEmail: {
      type: Boolean,
      default: false
    },
    displayInfo: {
      type: Boolean,
      default: true
    },
    external: {
      type: Boolean,
      default: true
    },
    hideDefaultHeader: {
      type: Boolean,
      default: false
    },
    adminView: {
      type: Boolean,
      default: false
    }
  },
  components: { QRViewer, FormSubmissionsTable },
  data() {
    return {
      loading: {
        assignments: false,
        analytics: []
      },
      dialog: {
        preview: false
      },
      item: null,
      table: {
        rowClass: () => "cursor-pointer",
        itemsPerPage: 10,
        page: 1,
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        items: [],
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestFilter: null,
        latestSort: null,
        expanded: []
      },
      qr: {
        dialog: false,
        error: null,
        formId: null
      },
      submission: {
        dialog: false,
        error: null,
        formId: null,
        formName: null
      }
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    async getItems(reset = false, source = null, preload = null) {
      try {
        // First, if they have a preloaded Id and it's an object, we add that now just in case the api result gets dismissed
        let alreadyPreloaded = false;
        if (preload && typeof preload == "object") {
          alreadyPreloaded = true;
          this.selectItem(preload, true);
        }
        console.log("Getting items from ", source);
        console.log({ reset, source, preload });
        this.table.loading = true;
        if (reset) this.table.options.page = 1;
        let options = this.table.options;
        let page = this.table.options.page || 1;
        let filter = `status = 'Active' && clientId = ${this.clientId}`;
        let limit = options.itemsPerPage;
        let offset = reset ? 0 : (page - 1) * limit;
        let sort = "formId DESC";
        if (this.table.options.sortBy && options.sortBy.length > 0) {
          let column = options.sortBy;
          console.log("Sort ", options);
          let type =
            options.sortDesc &&
            options.sortDesc.length > 0 &&
            options.sortDesc[0] === true
              ? "DESC"
              : "ASC";

          sort = column + " " + type;
        }
        this.table.latestSort = sort;
        this.table.latestFilter = filter;
        let params = {
          // filter,
          offset,
          limit,
          sort,
          clientId: this.clientId,
          // screen: this.sourcePage,
          // source: this.sourcePage,
          search: this.search
        };
        let response = await FormService.getFormsV2(
          null,
          this.clientId,
          params
        );

        // we have to compare states to see if we ignore results (only if them spam a filter)
        if (
          // filter != this.table.latestFilter ||
          this.search != params.search ||
          sort != this.table.latestSort ||
          page != this.table.options.page ||
          limit != this.table.options.itemsPerPage ||
          this.clientId != params.clientId
        ) {
          console.log("Ignoring API response");
          return;
        }

        console.log("Got programs ", response);
        this.table.total = response.result.count;
        let rows = response.result.rows.map(r => {
          if (r.startDate)
            r.startDate = moment(r.startDate).format("MMM D, YYYY h:mm a");
          if (r.endDate)
            r.endDate = moment(r.endDate).format("MMM D, YYYY h:mm a");
          return r;
        });

        this.table.items = rows;
        this.table.loading = false;
      } catch (err) {
        console.log("Error getting programs ", err);
        this.table.loading = false;
      }
    },
    selectItem(x, preventRemoval = false) {
      console.log("Selecting item ", x);
      this.$emit("select-item", {
        ...x,
        // Added because we can only pass one param in
        dontRemove: preventRemoval ? true : undefined
      });
      this.dialog.preview = false;
    },
    previewItem(item) {
      console.log("Previewing ", item);
      this.item = item;
      this.dialog.preview = true;
    },
    rowClick(item) {
      if (!this.adminView) {
        return this.selectItem(item, false);
      }

      let value = item?.item || item;
      console.log(value);

      if (value?.clientId === 0 && this.clientId !== 0)
        return console.log("Universal so we ignore");
      // this.getBudget(value);
      //add or remove the item from expanded array
      const index = this.table.expanded.findIndex(
        x => x?.formId == value?.formId
      );
      console.log("Expanded index ", index);
      if (index === -1) {
        this.table.expanded.push(value);

        if (value.status === "Active" || value.status === "Archived")
          this.getAnalytics(value.formId);
      } else {
        this.table.expanded = this.table.expanded.filter(
          x => x?.formId != value?.formId
        );
      }
    },
    async getAnalytics(formId) {
      try {
        this.loading.analytics.push(formId);
        let response = await FormService.getFormAnalyticsV2(
          formId
          // { clientId }
        );

        console.log("Got form analytics ", response);
        let index = this.table.items.findIndex(x => x.formId == formId);
        console.log("INDEX", index);
        if (index !== -1) {
          this.table.items[index].analytics = response?.result;
        }
      } catch (err) {
        console.log("Error getting form analytics ", err);
        // this.table.loading = false;
      } finally {
        this.loading.analytics = this.loading.analytics.filter(
          x => x != formId
        );
      }
    },
    async loadQrCode(formUUID) {
      this.qr = {
        dialog: false,
        error: null,
        formUUID
      };
      this.qr.dialog = true;
    },
    async loadFormSubmissions(formId, formName) {
      this.submission = {
        dialog: true,
        error: null,
        formId,
        formName
      };
      // this.qr.dialog = true;
    }
  },
  computed: {
    ...mapState(["userProfile", "globalClientId"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    visibleItems() {
      if (this.table.loading) return [];
      return this.table.items || [];
    },
    headers() {
      return [
        {
          text: "Name",
          align: "start",
          sortable: true,
          value: "name",
          width: "25%"
        },
        // {
        //   text: "Status",
        //   align: "start",
        //   sortable: true,
        //   value: "status",
        //   width: "20%"
        // },
        this.adminView
          ? {
              text: "Status",
              align: "center",
              sortable: true,
              value: "status",
              width: "25%"
            }
          : {},
        this.displayInfo && !this.adminView
          ? {
              value: "info",
              width: "100px",
              align: "end",
              sortable: false
            }
          : undefined,
        // this.adminView
        //   ? {
        //       text: "Submissions",
        //       value: "submissions",
        //       align: "start",
        //       width: "10%",
        //       sortable: false
        //     }
        //   : undefined,
        this.adminView
          ? {
              text: "Submissions / Details",
              value: "data-table-expand",
              align: "end",
              width: "10%"
            }
          : undefined
      ].filter(Boolean);
    }
  },
  watch: {
    "table.options": {
      handler(newVal, oldVal) {
        console.log("New options ", newVal);
        console.log("Old options ", oldVal);
        // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
        var reset = false;
        var apiCall = true;
        // If none of these exist then we're probably loading the page for the first time so we reset
        if (
          !oldVal.itemsPerPage ||
          !oldVal.page ||
          !oldVal.sortBy ||
          !oldVal.sortDesc
        )
          reset = true;
        else {
          // If any values changed from their old to new states, then we reset because they're changing a sort
          if (
            newVal.itemsPerPage !== oldVal.itemsPerPage ||
            (newVal.sortBy &&
              oldVal.sortBy &&
              newVal.sortBy[0] !== oldVal.sortBy[0]) ||
            (newVal.sortDesc &&
              oldVal.sortDesc &&
              newVal.sortDesc[0] !== oldVal.sortDesc[0])
          )
            reset = true;
        }

        if (apiCall) this.getItems(reset, "watcher");
      },
      deep: true
    },
    clientId: function(newVal, oldVal) {
      // If client id changes, we get budgets!
      if (newVal != oldVal && oldVal != null) {
        this.getItems(true, "client watcher");
      }
    },
    search: function() {
      this.getItems(true, "search watcher");
    }
  }
};
</script>

<style scoped>
/* Removes box shadow from table expansion */
#table >>> .v-data-table__expanded__content {
  box-shadow: none;
}

/* Hover and background for expanded buttons */
.preview-button {
  cursor: pointer;
  padding: 4px 6px 4px 6px;
  border-radius: 4px;
}
.preview-button:hover {
  background-color: #eee;
}

/* Caps width of page view bar */
.page-views-bar {
  min-width: 225px;
  max-width: 330px;
}

.form-submission-button {
  cursor: pointer;
  padding: 8px 8px 8px 8px;
  border-radius: 5px;
  transition: background-color 0.3s ease-in-out;
  user-select: none;
}
.form-submission-button:hover {
  background-color: rgba(237, 237, 237, 0.7);
}
</style>
