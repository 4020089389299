import axios from "../axios";

const baseUrl =
  process.env.VUE_APP_FORM_URL ??
  `${process.env.VUE_APP_WHISTLE_API}/form-service`;

class FormService {
  // V2
  getFormsV2(uuid = undefined, clientId = undefined, params = {}) {
    return axios
      .get(`${baseUrl}/v2/form${uuid ? `/uuid/${uuid}` : ``}`, {
        params: { ...params, clientId }
      })
      .then(r => {
        return r?.data;
      });
  } // end getFormsV2

  getFormAnalyticsV2(formId) {
    return axios
      .get(`${baseUrl}/v2/form/${formId}/analytics`, {
        // params: { ...params, clientId }
      })
      .then(r => {
        return r?.data;
      });
  } // end getFormAnalyticsV2

  createFormSubmissionV2(uuid, body = {}, params = {}) {
    return axios
      .post(`${baseUrl}/v2/form-submission/uuid/${uuid}`, body, { params })
      .then(r => {
        return r?.data;
      });
  } // end createFormSubmissionV2

  createFormSubmissionFileUploadV2(formSubmissionId, body = {}, params = {}) {
    return axios
      .post(`${baseUrl}/v2/form-submission/${formSubmissionId}/file`, body, {
        params
      })
      .then(r => {
        return r?.data;
      });
  } // end createFormSubmissionFileUploadV2

  getFormSubmissionFileV2(formSubmissionId, params = {}) {
    return axios
      .get(`${baseUrl}/v2/form-submission/${formSubmissionId}/file`, {
        params: { ...params }
      })
      .then(r => {
        return r?.data;
      });
  } // end getFormSubmissionFileV2

  getFormSubmissionsV2(formId, params = {}) {
    return axios
      .get(`${baseUrl}/v2/form-submission/form/${formId}`, {
        params: { ...params }
      })
      .then(r => {
        return r?.data;
      });
  } // end getFormSubmissionsV2

  getFormFilterOptionsV2(formId, params = {}) {
    return axios
      .get(`${baseUrl}/v2/form/${formId}/filters`, {
        params: { ...params }
      })
      .then(r => {
        return r?.data;
      });
  } // end getFormFilterOptionsV2
}

export default new FormService();
