<template>
  <!-- <v-dialog v-model="display" :width="width"> -->
  <!-- <v-card rounded="0" class="px-12"> -->
  <div>
    <div class="d-flex justify-space-between align-center mb-4">
      <!-- <v-card-title class="word-break px-0 mx-0"
        >File Viewer{{ fileName ? `: ${fileName}` : `` }}</v-card-title
      > -->
      <h3 class="text-left mb-8">Uploaded File:</h3>
    </div>
    <v-img
      v-if="data.signedUrl && !data.imageError"
      :src="data.signedUrl"
      contain
      width="100%"
      height="auto"
      max-height="275"
      class="mb-8"
      v-on:error="failedToLoadImage"
    />
    <p v-else-if="error" class="red--text">{{ error }}</p>
    <p v-if="fileName">
      <v-icon v-if="data.imageError" class="mr-2">mdi-file</v-icon
      >{{ fileName }}
    </p>
    <v-btn
      depressed
      rounded
      color="brandCyan"
      class="white--text"
      width="150"
      :disabled="!data.signedUrl"
      :loading="loading.url"
      @click="downloadFile"
      ><v-icon>mdi-download</v-icon>Download</v-btn
    >

    <!-- <v-card-actions class="pt-4">
      <v-spacer />
      <v-btn text color="brandCyan" @click="close">Close</v-btn>
    </v-card-actions> -->
  </div>
  <!-- </v-card> -->
  <!-- </v-dialog> -->
</template>

<script>
import FormService from "@/services/FormService";

import { mapState } from "vuex";

export default {
  name: "FormsFileViewer",
  components: {},
  props: {
    width: {
      type: Number,
      default: 500
    },
    formSubmissionId: {
      type: Number
    },
    filePath: {
      type: String
    }
  },
  data() {
    var d = {
      display: true,
      url: null,
      error: null,

      loading: { page: false, url: false },

      data: {
        signedUrl: null,
        imageError: false
      },

      dialog: {
        loadingAPI: false,
        loadingAPI404: false,
        loadingAPIErrorMessage: ""
      }
    };
    return d;
  },
  created() {
    if (this.formSubmissionId) this.loadFormSubmissionFile();
  },

  methods: {
    failedToLoadImage(e) {
      console.log("Failed to load image");
      this.data.imageError = true;
    },
    downloadFile() {
      if (!this.data.signedUrl) return;
      window.open(this.data.signedUrl, "_blank");
    },
    close() {
      // if (!save)
      return this.reset();
    },
    reset() {
      this.$emit("close");
      this.display = false;
    },
    async loadFormSubmissionFile() {
      this.loading.url = true;
      this.dialog.loadingAPI = true;
      this.dialog.loadingAPIErrorMessage = "";
      this.dialog.loadingAPI404 = false;
      try {
        const res = await FormService.getFormSubmissionFileV2(
          this.formSubmissionId,
          undefined,
          {
            // includeQuestions: true
          }
        );
        console.log("Got signed url ", res?.result?.url);
        this.data.signedUrl = res?.result?.url;
        if (!this.data.signedUrl) throw { status: 404 };

        this.dialog.loadingAPI = false;
      } catch (e) {
        console.log("Failed to load file", e);
        if (e?.response?.status === 404 || e?.status === 404) {
          this.dialog.loadingAPI404 = true;
          this.dialog.loadingAPIErrorMessage =
            "We couldn't find the file you're trying to view. Please try again or contact us if you need further assistance.";
        } else
          this.dialog.loadingAPIErrorMessage =
            "There was an issue loading your file. Please try again or contact us if you need further assistance.";
      } finally {
        this.loading.url = false;
      }
    },
    generateFormUrl() {
      if (!this.formId) return;

      this.url = `${window.location.origin}/form/${this.formId}`;
    },
    async copyToClipboard() {
      if (!this.url) return;

      await navigator.clipboard.writeText(this.url);

      // const el = document.createElement("textarea");
      // el.value = this.url;
      // el.setAttribute("readonly", "");
      // el.style.position = "absolute";
      // el.style.left = "-9999px";
      // document.body.appendChild(el);
      // const selected =
      //   document.getSelection().rangeCount > 0
      //     ? document.getSelection().getRangeAt(0)
      //     : false;
      // el.s();
      // document.execCommand("copy");
      // //document.body.removeChild(el);
      // if (selected) {
      //   document.getSelection().removeAllRanges();
      //   document.getSelection().addRange(selected);
      // }
      this.snackbarText = "Copied URL to clipboard: " + this.url;
      this.snackbar = true;
    }
  },

  computed: {
    ...mapState(["clients", "userProfile"]),
    fileName() {
      const split = this.filePath?.split("/");
      return split?.[split?.length - 1];
    }
  },
  watch: {
    display: function(newVal) {
      if (!newVal) this.$emit("close");
    },
    groupId: function() {
      this.generateGroupUrl();
    },
    formId: function() {
      this.generateFormUrl();
    }
  }
};
</script>

<style></style>
