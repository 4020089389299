import Vue from "vue";
import VueRouter from "vue-router";
import Home from "@/views/Home.vue";
import LoginError from "@/views/LoginError.vue";
import Profile from "@/views/Profile.vue";
// import InvestorDashboard from "@/views/InvestorDashboard.vue";
import People from "@/views/PeopleV2.vue";
import ComingSoon from "@/views/ComingSoon.vue";
import Groups from "@/views/GroupsV2.vue";
import Budgets from "@/views/Budgets.vue";
import Awards from "@/views/Awards.vue";
import Procore from "@/views/Procore.vue";
import Badges from "@/views/Badges.vue";
import ActivityV4 from "@/views/ActivityV4.vue";
// import Activation from "@/views/Activation.vue";
import EmailUnsubscribe from "@/views/EmailUnsubscribe.vue";
import Reporting from "@/views/Reporting.vue";
import ProgramAdminV3 from "@/views/ProgramAdminV3.vue";
// import ProgramBuilder from "@/views/ProgramBuilderV4.vue";
import AccountManagement from "@/views/AccountManagement.vue";
import Learning from "@/views/Learning.vue";
import LearningDetails from "@/views/LearningDetails.vue";
// import LearningAdmin from "@/views/LearningAdmin.vue";
// import LearningBuilder from "@/views/LearningBuilder.vue";
import LearningWizard from "@/views/LearningWizard.vue";
import Programs from "@/views/Programs.vue";
import ProgramDetails from "@/views/ProgramDetailsV2.vue";
import NewsArticle from "@/views/NewsArticle.vue";
// import Communications from "@/views/Communications.vue";
import CommunicationAdmin from "@/views/CommunicationAdminV2.vue";
import CommunicationWizard from "@/views/CommunicationWizard.vue";
import SurveyPublic from "@/views/SurveysPublicV2.vue";
import SurveyPrivate from "@/views/SurveysPrivateV2.vue";
// import SurveyAdmin from "../views/SurveyAdmin.vue";
import SurveyBuilder from "../views/SurveyBuilder.vue";
// import Wallet from "../views/Wallet.vue";
import PaymentAdminV3 from "../views/PaymentAdminV3.vue";
// import PaymentWizard from "../components/PaymentsWizardV3.vue";
// import WalletV2 from "../views/WalletV2.vue";
import WalletV3 from "../views/WalletV3.vue";
// import PaymentAdmin from "../views/PaymentAdmin.vue";
import MarqetaCardActivationSuccess from "../views/MarqetaCardActivationSuccess.vue";
import MarqetaCardPINSuccess from "../views/MarqetaCardPINSuccess.vue";
import MarqetaVirtualCardHosted from "../views/MarqetaVirtualCardHosted.vue";
import MarqetaActivateCardHosted from "../views/MarqetaActivateCardHosted.vue";
import MarqetaCreatePINHosted from "../views/MarqetaCreatePINHosted.vue";
import ClientActivation from "@/views/ClientActivation.vue";
import MetricUI from "@/views/MetricUI.vue";
import PageNotFound from "@/views/PageNotFound.vue";
import ProgramWizard from "@/views/ProgramWizard.vue";
import BudgetWizard from "@/views/BudgetWizard.vue";
import AnnualRewardWizard from "@/views/AnnualRewardWizard.vue";
import RecognitionWizard from "@/views/RecognitionWizard.vue";
import Login from "@/views/Login.vue";
import LoginMagicLink from "@/views/LoginMagicLink.vue";
import UserCreatorFileWizard from "@/views/UserCreatorFileWizard.vue";
import AppClip from "@/views/AppClip.vue";
import InstantApp from "@/views/InstantApp.vue";
import ProcoreSemanticSearch from "@/views/ProcoreSemanticSearch.vue";
import Forms from "@/views/Forms.vue";
import FormsFileViewer from "@/views/FormsFileViewer.vue";

import { authGuard } from "@/auth/authGuard";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home
  },
  {
    path: "/login",
    name: "login",
    component: Login,
    props: true
  },
  {
    path: "/login/spirit",
    name: "spirit-login",
    component: Login,
    props: true
  },
  {
    path: "/login/generic/:provider",
    name: "generic-3pa-login",
    component: Login,
    props: true
  },
  {
    path: "/magic-link/:magicLinkId/:magicLink",
    name: "magic-link",
    component: LoginMagicLink,
    props: true
  },
  {
    path: "/login-error",
    name: "login-error",
    component: LoginError
  },
  {
    path: "/unsubscribe/email",
    name: "emailunsubscribe",
    component: EmailUnsubscribe
  },
  {
    path: "/activity",
    name: "activity",
    component: ActivityV4,
    beforeEnter: authGuard
  },
  {
    path: "/setup-complete",
    name: "setup-complete",
    component: ActivityV4,
    beforeEnter: authGuard
  },
  {
    path: "/profile",
    name: "profile",
    component: Profile,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/profile/slack-success",
    name: "profileslacksuccess",
    component: Profile,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/slack-setup-user",
    name: "slacksetupuser",
    component: Profile,
    props: true,
    beforeEnter: authGuard
  },
  // {
  //   path: "/investorDashboard",
  //   name: "InvestorDashboard",
  //   component: InvestorDashboard,
  //   props: { rides: "testings" },
  //   params: { rides: "testings" },
  //   beforeEnter: authGuard
  // },
  {
    path: "/coming-soon",
    name: "ComingSoon",
    component: ComingSoon
  },
  {
    path: "/people",
    name: "people",
    component: People,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/people/file-wizard",
    name: "peoplefilewizard",
    component: UserCreatorFileWizard,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/people/groups",
    name: "groups",
    component: Groups,
    beforeEnter: authGuard
  },
  {
    path: "/programadmin",
    name: "programadmin",
    component: ProgramAdminV3,
    beforeEnter: authGuard,
    props: true
  },
  // {
  //   path: "/programbuilder",
  //   name: "programbuilder",
  //   component: ProgramBuilder,
  //   props: true,
  //   beforeEnter: authGuard
  // },
  {
    path: "/program-wizard",
    name: "programwizard",
    component: ProgramWizard,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/learningbuilder",
    redirect: "/learning-wizard"
    // name: "learningbuilder",
    // component: LearningBuilderV2,
    // props: true,
    // beforeEnter: authGuard
  },
  {
    path: "/learning-wizard",
    name: "learningwizard",
    component: LearningWizard,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/budgets",
    name: "budgets",
    component: Budgets,
    beforeEnter: authGuard
  },
  {
    path: "/budgets/awards",
    name: "awards",
    component: Awards,
    beforeEnter: authGuard
  },
  {
    path: "/budgets/badges",
    name: "badges",
    component: Badges,
    beforeEnter: authGuard
  },
  {
    path: "/budgets/:type",
    name: "budgets",
    component: Budgets,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/procore",
    name: "procore",
    component: Procore
    // props: true,
    // beforeEnter: authGuard
  },
  {
    path: "/procore-semantic-search",
    name: "procore-semantic-search",
    component: ProcoreSemanticSearch
    // props: true,
    // beforeEnter: authGuard
  },
  // {
  //   path: "/activation",
  //   name: "activation",
  //   component: Activation,
  //   beforeEnter: authGuard
  // },
  {
    // This is a public route, so we don't need a component for it. It's handled globally in App.vue
    path: "/signup/:onboardingToken",
    name: "user-signup",
    // component: ActivationV2,
    props: true
  },
  {
    // This is a public route, so we don't need a component for it. It's handled globally in App.vue
    path: "/signup/static/:onboardingToken",
    name: "user-signup-static",
    // component: ActivationV2,
    props: true
  },
  {
    path: "/reporting",
    name: "reporting",
    component: Reporting,
    beforeEnter: authGuard
  },
  {
    path: "/accountManagement",
    name: "accountManagement",
    component: AccountManagement,
    beforeEnter: authGuard
  },
  {
    path: "/communications",
    name: "communications",
    component: CommunicationAdmin,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/communication-wizard",
    name: "communicationwizard",
    props: true,
    component: CommunicationWizard, //PaymentWizard,
    beforeEnter: authGuard
  },
  {
    path: "/learning",
    name: "learning",
    component: Learning,
    beforeEnter: authGuard
  },
  {
    path: "/learning/:learningId",
    name: "learningdetails",
    component: LearningDetails,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/learningadmin",
    name: "learningadmin",
    component: ProgramAdminV3,
    beforeEnter: authGuard,
    props: true
    // redirect: "/programadmin"
    // component: LearningAdmin,
    // beforeEnter: authGuard
  },
  {
    path: "/surveyadmin",
    name: "surveyadmin",
    component: ProgramAdminV3,
    beforeEnter: authGuard,
    props: true
    // redirect: "/programadmin"
    // component: SurveyAdmin,
    // beforeEnter: authGuard
  },
  {
    path: "/surveybuilder",
    name: "surveybuilder",
    component: SurveyBuilder,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/programs",
    name: "programs",
    component: Programs,
    beforeEnter: authGuard
  },
  {
    path: "/programs/:programId",
    name: "programdetails",
    component: ProgramDetails,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/news/:messageId",
    name: "newsArticle",
    props: true,
    component: NewsArticle
  },
  {
    path: "/survey/public/:surveyId",
    name: "surveyPublic",
    props: true,
    component: SurveyPublic
  },
  {
    path: "/survey/:surveyId",
    name: "surveyPrivate",
    props: true,
    component: SurveyPrivate,
    beforeEnter: authGuard
  },
  // {
  //   path: "/robin",
  //   name: "robin",
  //   component: Robin,
  //   beforeEnter: authGuard,
  // },
  {
    path: "/wallet",
    name: "wallet",
    component: WalletV3,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/wallet/app-clip",
    name: "walletappclip",
    component: AppClip,
    props: true
    // beforeEnter: authGuard
  },
  {
    path: "/android-instant-app",
    name: "androidinstantapp",
    component: InstantApp,
    props: true
    // beforeEnter: authGuard
  },
  {
    path: "/giving",
    name: "giving",
    component: Programs,
    props: true,
    beforeEnter: authGuard
  },
  {
    path: "/wallet/activate/success",
    name: "walletactivationsuccess",
    component: MarqetaCardActivationSuccess
  },
  {
    path: "/wallet/pin/success",
    name: "walletpinsuccess",
    component: MarqetaCardPINSuccess
  },
  // {
  //   path: "/wallet/virtualcardhosted/",
  //   name: "marqetavirtualcardhosted1",
  //   props: true,
  //   component: MarqetaVirtualCardHosted,
  // },
  {
    path: "/wallet/virtualcardhosted/",
    name: "marqetavirtualcardhosted",
    props: true,
    component: MarqetaVirtualCardHosted
  },
  {
    path: "/wallet/activatecardhosted/",
    name: "marqetaactivatecardhosted",
    props: true,
    component: MarqetaActivateCardHosted
  },
  {
    path: "/wallet/createpinhosted/",
    name: "marqetacreatepinhosted",
    props: true,
    component: MarqetaCreatePINHosted
  },
  {
    path: "/paymentadmin",
    name: "paymentadmin",
    props: true,
    component: PaymentAdminV3,
    beforeEnter: authGuard
  },
  {
    path: "/payment-wizard",
    name: "paymentwizard",
    props: true,
    component: RecognitionWizard, //PaymentWizard,
    beforeEnter: authGuard
  },
  {
    path: "/recurring-payment-wizard",
    name: "recurringpaymentwizard",
    props: true,
    component: RecognitionWizard, //PaymentWizard,
    beforeEnter: authGuard
  },
  {
    path: "/recognition-wizard",
    name: "recognitionwizard",
    props: true,
    component: RecognitionWizard, //PaymentWizard,
    beforeEnter: authGuard
  },
  {
    path: "/budget-wizard",
    name: "budgetwizard",
    props: true,
    component: BudgetWizard, //PaymentWizard,
    beforeEnter: authGuard
  },
  {
    path: "/annual-reward-wizard",
    name: "annualrewardwizard",
    props: true,
    component: AnnualRewardWizard,
    beforeEnter: authGuard
  },
  {
    path: "/setup",
    name: "setup",
    component: ClientActivation
  },
  {
    path: "/slack-setup",
    name: "slacksetup",
    component: ClientActivation
  },
  {
    path: "/progress",
    name: "progress",
    props: true,
    component: MetricUI,
    beforeEnter: authGuard
  },
  {
    path: "/form/:uuid",
    name: "forms",
    props: true,
    component: Forms
  },
  {
    path: "/form/file/:formSubmissionId",
    name: "formfileviewer",
    props: true,
    component: FormsFileViewer,
    beforeEnter: authGuard
  },
  { path: "*", component: PageNotFound }
];

const router = new VueRouter({
  routes,
  mode: "history",
  scrollBehavior() {
    return { x: 0, y: 0 };
  }
});
window.router = router;
export default router;
