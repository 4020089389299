<template>
  <div>
    <v-row no-gutters>
      <v-col cols="12">
        <div class="full-width d-flex justify-end">
          <v-btn
            v-if="permissions.includes('learning:create:module')"
            depressed
            rounded
            color="brandCyan"
            class="white--text mb-3"
            @click="showSubscriptionDialog"
          >
            <v-icon color="white" class="mr-1">mdi-plus-circle</v-icon>
            <span style="margin-top:2px;"
              >New {{ displaySurveys ? "Survey" : "Learning Module" }}</span
            ></v-btn
          >
        </div>
        <v-data-table
          :key="displaySurveys ? 0 : 1"
          :headers="headers"
          :items="visibleItems"
          :items-per-page="table.itemsPerPage"
          :footer-props="table.footerProps"
          :options.sync="table.options"
          :server-items-length="table.total"
          :show-expand="false"
          :expanded="[]"
          :loading="table.loading"
          loading-text="loading"
          must-sort
          sort-by="learningModuleId"
          sort-desc
          :no-data-text="
            `No ${displaySurveys ? 'surveys' : 'modules'} available`
          "
          :no-results-text="
            `No ${displaySurveys ? 'surveys' : 'modules'} found`
          "
          item-key="learningModuleId"
          id="learning-table"
        >
          <template v-slot:[`item.displayName`]="{ item }">
            <span class="word-break">{{ item.displayName }}</span>
          </template>
          <template v-slot:[`item.actions`]="{ item }">
            <div class="d-flex justify-end mr-7">
              <v-btn
                v-if="displaySurveys && item.visibilityType == 'PUBLIC'"
                icon
                class="mr-1"
                @click="copyToClipboard(item.learningModuleId)"
                ><v-icon color="brand">mdi-link</v-icon></v-btn
              >
              <v-btn
                icon
                v-if="
                  (item.clientId != 0 ||
                    (item.clientId == 0 &&
                      userProfile.clientId == whistleClientId &&
                      clientId == 0)) &&
                    permissions.includes('learning:create:module')
                "
                class="mr-1"
              >
                <v-icon
                  color="brand"
                  @click="
                    loadBuilder(
                      {
                        learningModuleId: item.learningModuleId,
                        clientId: item.clientId,
                        preloadedClientId: item.clientId,
                        editingLearning: true
                      },
                      {
                        learningModuleId: item.learningModuleId,
                        clientId: item.clientId
                      }
                    )
                  "
                  >mdi-pencil
                </v-icon>
              </v-btn>
              <!-- <v-tooltip top open-delay="200"> -->
              <!-- <template v-slot:activator="{ on, attrs }"> -->
              <v-btn
                icon
                class="mr-1"
                v-if="permissions.includes('learning:create:module')"
              >
                <v-icon color="brand" @click="duplicate(item)"
                  >mdi-content-copy
                </v-icon>
              </v-btn>
              <!-- </template>
                <span>Duplicate Module</span>
              </v-tooltip> -->
              <v-btn icon v-if="permissions.includes('learning:delete:module')">
                <v-icon
                  color="brand"
                  @click="removeModuleDialog(item.learningModuleId)"
                  :class="
                    item.clientId != 0 ||
                    userProfile.clientId == whistleClientId
                      ? ''
                      : 'hidden'
                  "
                  >mdi-delete
                </v-icon>
              </v-btn>
            </div>
          </template>
          <template v-slot:loading>
            <!-- {{ item }} -->
            <v-progress-circular
              indeterminate
              color="primary"
              class="my-8"
              :size="50"
              :width="4"
            ></v-progress-circular>
          </template>
        </v-data-table>
      </v-col>
    </v-row>
    <!-- Loading dialog for no whistle users -->
    <v-dialog persistent v-model="duplicateModule.loadingModal" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div v-if="duplicateModule.error != true">
          <div class="d-flex justify-space-between align-center mx-2">
            <v-card-title class="word-break align-text-left exit-warning-text">
              Duplicating {{ displaySurveys ? "survey" : "module" }}...hold
              tight!
            </v-card-title>
          </div>
          <!-- Robin animation -->
          <div class="mx-2 mb-4 pb-2 d-flex flex-column align-center">
            <Robin
              animation="fluttering"
              :width="120"
              class="mx-auto robin-animation"
              :loop="true"
            />
          </div>
        </div>
        <div v-else>
          <p class="pl-4 full-width align-text-left">
            It looks like there was an error duplicating the module.
          </p>
          <p class="pl-4 full-width align-text-left">
            If the problem continues, feel free to reach out to customer service
            via one of the following methods...
          </p>
          <p class="pl-4 align-text-left full-width">
            Email -
            <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
          </p>
          <p class="pl-4 align-text-left full-width">
            Phone (Toll Free) - <b>(855) 264-3329</b>
          </p>
        </div>
        <v-card-actions class="mx-auto" v-if="duplicateModule.error == true">
          <v-btn
            color="primary"
            @click="
              duplicateModule = {
                clientSelectModal: false,
                module: null,
                error: false,
                clientId: null,
                loading: false,
                loadingModal: false
              }
            "
            width="170"
            >Okay</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Subscription tier blocker -->
    <!-- <v-dialog
      v-if="subscriptionDialog"
      v-model="subscriptionDialog"
      width="500"
    >
      <v-card
        rounded="0"
        class="d-flex justify-center flex-column text-left py-6 px-10"
      >
        <div class="d-flex justify-space-between align-center mx-n4">
          <v-card-title class="word-break text-left">
            Time to upgrade your Whistle subscription!
          </v-card-title>
        </div>
        <p class="py-2 text-left" key="0">
          Looks like your current subscription level{{
            subscription.name ? `, ${subscription.name},` : ""
          }}
          has a limit of {{ subscription.limit || 1 }} learning
          {{
            (subscription.limit || 1) == 1 ? "module" : "modules"
          }}.<br />Please contact us if you're interested in upgrading your
          plan!
        </p>
        <p>
          Email -
          <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
        </p>
        <p>Phone (Toll Free) - <b>(855) 264-3329</b></p>
        <div>
          <v-card-actions class="mx-8">
            <v-btn
              color="brandCyan"
              depressed
              width="170"
              class="mx-auto white--text"
              @click="closeSubscriptionDialog"
              >Close</v-btn
            >
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog> -->
    <!-- Client select dialog for whistle users -->
    <v-dialog
      v-if="duplicateModule.clientSelectModal"
      persistent
      v-model="duplicateModule.clientSelectModal"
      width="500"
    >
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div v-if="duplicateModule.error != true">
          <div class="d-flex justify-space-between align-center mx-2">
            <v-card-title class="word-break align-text-left exit-warning-text">
              Choose the client you would like this duplicate to be added to
            </v-card-title>
          </div>
          <!-- <v-card-subtitle
            class="word-break"
            v-if="duplicateModule.module != null"
            >{{ duplicateModule.module.displayName }}
          </v-card-subtitle> -->

          <v-autocomplete
            outlined
            dense
            class="mb-6 mx-2 px-4 "
            hide-details
            :items="clients"
            item-text="formattedName"
            item-value="clientId"
            label="Client"
            v-model="duplicateModule.clientId"
          >
          </v-autocomplete>
        </div>
        <div v-else>
          <!-- <p class="pl-4 full-width align-text-left"></p> -->
          <v-card-title class="word-break align-text-left exit-warning-text">
            It looks like there was an error duplicating the module.
          </v-card-title>
          <p class="pl-4 full-width align-text-left">
            If the problem continues, feel free to reach out to customer service
            via one of the following methods...
          </p>
          <p class="pl-4 align-text-left full-width">
            Email -
            <b><a href="mailto:help@wewhistle.com">help@wewhistle.com</a></b>
          </p>
          <p class="pl-4 align-text-left full-width">
            Phone (Toll Free) - <b>(855) 264-3329</b>
          </p>
        </div>
        <v-card-actions class="mx-8" v-if="duplicateModule.error != true">
          <v-btn
            color="primary"
            depressed
            @click="
              duplicateModule = {
                clientSelectModal: false,
                module: null,
                error: false,
                clientId: null,
                loading: false,
                loadingModal: false
              }
            "
            outlined
            width="170"
            :disabled="duplicateModule.loading == true"
            >Cancel</v-btn
          >
          <v-spacer />
          <v-btn
            depressed
            color="primary"
            width="170"
            @click="duplicate(duplicateModule.module)"
            :disabled="
              duplicateModule.clientId == null ||
                duplicateModule.loading == true
            "
          >
            <span v-if="duplicateModule.loading != true">Duplicate</span>
            <v-progress-circular
              v-else
              indeterminate
              size="20"
              :width="3"
              color="white"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
        <v-card-actions class="mx-auto" v-else>
          <v-btn
            depressed
            color="primary"
            @click="
              duplicateModule = {
                clientSelectModal: false,
                module: null,
                error: false,
                clientId: null,
                loading: false,
                loadingModal: false
              }
            "
            width="170"
            >Okay</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Module delete dialog -->
    <v-dialog v-model="deleteModuleConfirmation.dialog" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-2">
          <v-icon color="error" x-large class="exit-warning-icon mr-2"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            Are you sure you want to delete this
            {{ displaySurveys ? "survey" : "module" }}?
          </v-card-title>
        </div>
        <v-card-subtitle class="word-break mb-3"
          >This deletes all media and questions associated with it
        </v-card-subtitle>

        <v-card-actions class="mx-8">
          <v-btn
            color="brandCyan"
            @click="
              deleteModuleConfirmation = {
                dialog: false,
                learningModuleId: null
              }
            "
            outlined
            width="170"
            >Cancel</v-btn
          >
          <v-spacer />
          <v-btn
            color="brandCyan"
            class="white--text"
            depressed
            @click="removeModule"
            width="170"
            >Delete</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Delete error dialog for programs -->
    <v-dialog v-model="dialogDeleteErrorMessage" width="500">
      <v-card rounded="0" class="d-flex justify-center flex-column pa-6">
        <div class="d-flex justify-space-between align-center mx-2 mb-5">
          <v-icon color="error" x-large class="exit-warning-icon mr-4"
            >mdi-alert</v-icon
          >
          <v-card-title class="word-break align-text-left exit-warning-text">
            We found programs that are using the
            {{ displaySurveys ? "survey" : "learning module" }} that you're
            trying to delete
          </v-card-title>
        </div>
        <p
          v-for="error in dialogDeleteErrorArray"
          :key="`${error.programId}-${error.learningModuleId}`"
          class="mb-2"
        >
          Program '<span class="font-weight-bold">{{ error.displayName }}</span
          >'
        </p>

        <v-card-actions class="mx-auto">
          <v-btn
            color="brandCyan"
            class="white--text"
            depressed
            @click="dialogDeleteErrorMessage = false"
            width="140"
            >Close</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <!-- Dialog used for displaying copied url snackbar. -->
    <v-snackbar
      v-model="snackbar"
      :timeout="snackbarTimeout"
      :multi-line="true"
      :v-html="snackbarText"
    >
      {{ snackbarText }}

      <template v-slot:action="{ attrs }">
        <v-btn color="blue" text v-bind="attrs" @click="snackbar = false">
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Dialog used for displaying loading message. -->
    <LoadingDialog
      :showDialog="loading.display"
      :header="loading.header"
      :line1="loading.line1"
      :line2="loading.line2"
    ></LoadingDialog>
  </div>
</template>

<script>
import LearningService from "@/services/LearningService";

import LoadingDialog from "@/components/loadingDialog.vue";
import Robin from "@/components/Robin.vue";

import { mapState } from "vuex";

export default {
  name: "LearningLibrary",
  props: {
    displaySurveys: {
      type: Boolean,
      default: false
    },
    search: {
      type: String
    },
    sourcePage: {
      type: String,
      default: "programadmin"
    },
    sourcePageProps: {
      type: Object,
      default: undefined
    }
  },
  components: { Robin, LoadingDialog },
  data() {
    return {
      whistleClientId: 1,
      loading: {
        display: false,
        header: null,
        line1: null,
        line2: null
      },
      offBrandCyan: "#4DC9F9",
      offBrandBrightGreen: "#A2D76D",
      table: {
        itemsPerPage: 10,
        page: 1,
        // Used if the user ends up mashing the table page button, then we're waiting on multiple responses so we know which page to actually store the data for
        awaitingPage: 1,
        search: null,
        debounceSearch: null,
        loading: false,
        items: [],
        total: 0,
        selected: [],
        options: {},
        footerProps: {
          "items-per-page-options": [10, 25, 50, 100]
        },
        latestFilter: null,
        latestSort: null
      },
      duplicateModule: {
        clientSelectModal: false,
        module: null,
        clientId: null,
        error: false,
        loading: false,
        loadingModal: false
      },
      // subscriptionDialog: false,
      deleteModuleConfirmation: {
        dialog: false,
        learningModuleId: null
      },
      dialogDeleteErrorMessage: false,
      dialogDeleteErrorArray: [],
      snackbar: false,
      snackbarTimeout: 6000,
      snackbarText: "Copied URL to clipboard"
    };
  },
  created() {},
  mounted() {},
  beforeDestroy() {},
  methods: {
    loadAction(item) {
      console.log("loading action ", item);
      this.$emit("load-action", item);
    },
    loadBuilder(props = {}, query = {}) {
      if (this.displaySurveys)
        this.$router.push({
          name: "surveybuilder",
          params: {
            ...props,
            previousRoute: this.sourcePage,
            previousRouteProps: this.sourcePageProps
          },
          query
        });
      else
        this.$router.push({
          name: "learningwizard",
          params: {
            ...props,
            sourcePage: this.sourcePage,
            sourcePageProps: this.sourcePageProps
          },
          query
        });
    },
    async getModules(reset = false, source = null) {
      try {
        console.log("Getting modules from ", source);
        this.table.loading = true;
        if (reset) this.table.options.page = 1;
        let options = this.table.options;
        let page = this.table.options.page || 1;
        let search = this.search ? this.search.replace(/'/g, "\\'") : null;
        let filter = `status != 'Deleted' && (clientId = ${
          this.clientId
        } || clientId = 0) && type = ${
          this.displaySurveys ? "Survey" : "Learning"
        }`;

        if (search) {
          // If search we need to search for every field that's in the table
          filter += ` && (displayName.like('%${search}%') || status.like('%${search}%') || learningModuleId.like('%${search}%'))`;
        }
        let limit = options.itemsPerPage;
        let offset = reset ? 0 : (page - 1) * limit;
        if (this.table.options.sortBy && options.sortBy.length > 0) {
          let column = options.sortBy;
          let type =
            options.sortDesc &&
            options.sortDesc.length > 0 &&
            options.sortDesc[0] === true
              ? "DESC"
              : "ASC";

          if (column == "formattedSendDate") sort = `sendDate ${type}`;
          else var sort = column + " " + type;
        } else sort = "learningModuleId DESC";
        this.table.latestSort = sort;
        this.table.latestFilter = filter;
        let params = {
          filter,
          offset,
          limit,
          sort
          // Program Cache is used to get group estimates
          // Program Element is used so we know when to show update progress
          // expand: "ProgramCache,ProgramElement"
        };
        let response = await LearningService.getLearningModulesV2(params);

        // we have to compare states to see if we ignore results (only if them spam a filter)
        if (
          filter != this.table.latestFilter ||
          sort != this.table.latestSort
        ) {
          console.log("Ignoring response");
          return;
        }

        console.log("Got modules ", response);
        this.table.total = response.result.count;
        // if (reset || wipeArray)
        //   this.table.messages = new Array(this.table.total).fill(undefined);
        let rows = response.result.rows.map(r => {
          return r;
        });

        // If at the beginning we just grab the first results and then append the rest of the empty array
        // If not the beginning, we append the existing fetched array, then the new results, and anything after
        // this.table.users = rows;
        // if (offset == 0)
        this.table.items = rows;
        this.table.loading = false;
      } catch (err) {
        console.log("Error getting modules ", err);
        this.table.loading = false;
      }
    },
    async duplicate(learningModule) {
      try {
        // If userProfile.clientId = 1 - pop up modal to confirm client
        // else, just duplicate it using thier userProfile.clientId
        // TODO: Make sure subcients will work
        // Check subscription level
        // if (this.subscription && !this.subscription.createMore) {
        //   this.subscriptionDialog = true;
        //   return;
        // }
        const targetModule = learningModule;
        if (
          this.userProfile.clientId == this.whistleClientId &&
          !this.duplicateModule.clientSelectModal
        ) {
          console.log("Duplicating module", learningModule);
          this.duplicateModule.module = targetModule;
          this.duplicateModule.clientSelectModal = true;
        } else {
          // Non whistle user or client is selected, go ahead
          console.log("Hitting API for duplicating");
          this.duplicateModule.loading = true;
          this.duplicateModule.clientSelectModal = false;
          this.duplicateModule.loadingModal = true;
          let newModuleResponse = await LearningService.duplicateLearningModuleV2(
            targetModule.learningModuleId,
            `?clientId=${
              this.duplicateModule.clientId == null
                ? this.userProfile.clientId
                : this.duplicateModule.clientId
            }`
          );

          this.duplicateModule.loading = false;
          this.duplicateModule.loadingModal = false;
          console.log(newModuleResponse);
          const { learningModuleId, clientId } = newModuleResponse;
          if (learningModuleId) {
            this.loadBuilder(
              // Needed for surveys
              {
                preloadedClientId: clientId,
                learningModuleId: learningModuleId,
                editingLearning: true
              },
              // Needed for modules
              {
                learningModuleId: learningModuleId,
                clientId: clientId
              }
            );
          } else {
            this.duplicateModule.error = true;
          }
        }
      } catch (err) {
        //  else if (newModuleRes.error_code == "1160126") {
        // this.subscriptionDialog = true;
        // }
        console.log("error duplicating module", err);
        // if (err.error_code && err.error_code == "1160126") {
        //   return (this.subscriptionDialog = true);
        // }
        this.duplicateModule.error = true;
      }
    },
    showSubscriptionDialog() {
      // When we fetch their learning modules, we get a subscription summary that tells us
      // if the user can create more modules
      // If they can, everything is normal, otherwise, we block them
      // if (this.subscription && !this.subscription.createMore) {
      //   this.subscriptionDialog = true;
      // } else {
      this.loadBuilder({
        preloadedModule: null,
        preloadedClientId: null,
        learningModuleId: null
      });
      // if (this.displaySurveys)
      //   this.$router.push({
      //     name: "surveybuilder",
      //     params: {
      //       preloadedModule: null,
      //       preloadedClientId: this.clientId,
      //       previousRoute: this.sourcePage,
      //       previousRouteProps: this.sourcePageProps
      //     }
      //   });
      // else
      //   this.$router.push({
      //     name: "learningwizard",
      //     params: {
      //       learningModuleId: null,
      //       preloadedModule: null,
      //       sourcePage: this.sourcePage,
      //       sourcePageProps: this.sourcePageProps
      //     }
      //   });
      // }
    },
    // closeSubscriptionDialog() {
    //   if (this.duplicateModule.loading == true) {
    //     this.duplicateModule = {
    //       clientSelectModal: false,
    //       module: null,
    //       error: false,
    //       clientId: null,
    //       loading: false,
    //       loadingModal: false
    //     };
    //   }
    //   this.subscriptionDialog = false;
    // },
    removeModuleDialog(id) {
      this.deleteModuleConfirmation = {
        dialog: true,
        learningModuleId: id
      };
    },
    removeModule() {
      // For now, this just deletes the chapter and the card association. The card and media remain active
      this.loading.display = true;
      this.loading.header = `Deleting ${
        this.displaySurveys ? "Survey" : "Module"
      }`;
      this.loading.line1 = "This should just take a minute.";
      this.loading.line2 = null;
      console.log(
        "Deleting module ",
        this.deleteModuleConfirmation.learningModuleId
      );

      LearningService.deleteLearningModule(
        this.deleteModuleConfirmation.learningModuleId
      )
        .then(response => {
          console.log(
            "deleted module ",
            this.deleteModuleConfirmation.learningModuleId
          );
          console.log(response);
          this.loading.display = false;
          console.log("Removing module from array");

          this.getModules();
          // We have to do this for both the main module array and the visibleModules
          // var modIndex = this.viewTable.visibleModules.findIndex(
          //   x =>
          //     x.learningModuleId ==
          //     this.deleteModuleConfirmation.learningModuleId
          // );
          // if (modIndex != -1) {
          //   this.viewTable.visibleModules.splice(modIndex, 1);
          // }

          // modIndex = this.modules.findIndex(
          //   x =>
          //     x.learningModuleId ==
          //     this.deleteModuleConfirmation.learningModuleId
          // );
          // if (modIndex != -1) {
          //   this.modules.splice(modIndex, 1);
          // }

          this.deleteModuleConfirmation = {
            dialog: false,
            learningModuleId: null
          };
        })
        .catch(err => {
          console.log("Error deleting module", err);
          if (err && err.data && err.data.length) {
            this.dialogDeleteErrorMessage = true;
            this.dialogDeleteErrorArray = err.data;
            this.deleteModuleConfirmation = {
              dialog: false,
              learningModuleId: null
            };
          }
          this.loading.display = false;
        });
    },
    async copyToClipboard(content) {
      content = window.location.origin + "/survey/public/" + content;
      await navigator.clipboard.writeText(content);
      // const el = document.createElement("textarea");
      // el.value = content;
      // el.setAttribute("readonly", "");
      // el.style.position = "absolute";
      // el.style.left = "-9999px";
      // document.body.appendChild(el);
      // const selected =
      //   document.getSelection().rangeCount > 0
      //     ? document.getSelection().getRangeAt(0)
      //     : false;
      // el.select();
      // document.execCommand("copy");
      // //document.body.removeChild(el);
      // if (selected) {
      //   document.getSelection().removeAllRanges();
      //   document.getSelection().addRange(selected);
      // }
      this.snackbarText = "Copied URL to clipboard: " + content;
      this.snackbar = true;
    }
  },
  computed: {
    ...mapState(["userProfile", "permissions", "globalClientId", "clients"]),
    clientId: {
      get: function() {
        return this.globalClientId;
      },
      set: function(newVal) {
        this.$store.dispatch("setClientId", newVal);
      }
    },
    isMobile() {
      return this.$vuetify.breakpoint.xs || this.$vuetify.breakpoint.sm;
    },
    // subscription() {
    //   return this.displaySurveys
    //     ? this.surveySubscription
    //     : this.learningSubscription;
    // },
    visibleItems() {
      let array = this.table.items || [];

      array = array.filter(x => x.status != "Deleted");

      if (this.table.loading) return [];
      return array;
    },
    headers() {
      return [
        {
          text: "Id",
          value: "learningModuleId",
          width: "10%",
          align:
            this.userProfile &&
            this.userProfile.clientId == this.whistleClientId
              ? "start"
              : " d-none"
        },
        {
          text: "Name",
          value: "displayName",
          width: "50%"
        },
        {
          text: "Status",
          value: "status",
          width: "40%"
        },
        { text: "", value: "actions", width: "10%" }
      ];
    }
  },
  watch: {
    "table.options": {
      handler(newVal, oldVal) {
        console.log("New options ", newVal);
        console.log("Old options ", oldVal);
        // Used to indicate if we wipe the table and start with an empty array, versus pushing more data on
        var reset = false;
        var apiCall = true;
        // If none of these exist then we're probably loading the page for the first time so we reset
        if (
          !oldVal.itemsPerPage ||
          !oldVal.page ||
          !oldVal.sortBy ||
          !oldVal.sortDesc
        )
          reset = true;
        else {
          // If any values changed from their old to new states, then we reset because they're changing a sort
          if (
            newVal.itemsPerPage !== oldVal.itemsPerPage ||
            (newVal.sortBy &&
              oldVal.sortBy &&
              newVal.sortBy[0] !== oldVal.sortBy[0]) ||
            (newVal.sortDesc &&
              oldVal.sortDesc &&
              newVal.sortDesc[0] !== oldVal.sortDesc[0])
          )
            reset = true;
        }
        // This means that they just hit the next button. We have to check what the max page we've loaded in is
        // We can optimize and load in existing data
        // if (
        //   !reset &&
        //   oldVal.page !== newVal.page &&
        //   this.table.messages.filter(x => x !== undefined).length >
        //     (newVal.page - 1) * newVal.itemsPerPage
        // ) {
        //   console.log("We're not going to make the API call");
        //   apiCall = false;
        // }
        if (apiCall) this.getModules(reset, "watcher");
      },
      deep: true
    },
    clientId: function(newVal, oldVal) {
      // If client id changes, we get budgets!
      if (newVal != oldVal && oldVal != null) {
        this.getModules();
      }
    },
    search: function() {
      this.getModules(true, "search watcher");
    }
  }
};
</script>

<style scoped></style>
